import React, { useState } from 'react';

export const CopyIcon = ({ size = 16, color = 'white', stroke = 2 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={size} height={size}>
    {/* Bottom rectangle */}
    <rect
      x="6"
      y="6"
      width="12"
      height="12"
      rx="2"
      ry="2"
      fill="none"
      stroke={color}
      strokeWidth={stroke}
      opacity="0.5"
    />
    {/* Top rectangle */}
    <rect
      x="2"
      y="10"
      width="12"
      height="12"
      rx="2"
      ry="2"
      fill="none"
      stroke={color}
      strokeWidth={stroke}
    />
  </svg>
);

const CopyButton = ({ address }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(address).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Reset to "Copy" after 2 seconds
    });
  };

  return (
    <button
      onClick={handleCopy}
      style={{
        marginLeft: '8px',
        cursor: 'pointer',
        border: 'none',
        background: 'none',
        display: 'inline-flex',
        alignItems: 'center',
        height: '20px', // Ensures consistent height
        padding: 0, // No extra padding that might cause alignment issues
      }}
      title="Copy to clipboard"
    >
      {isCopied ? (
        <span
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            color: 'white',
            opacity: 0.5,
            fontSize: '14px',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="16"
            height="16"
            style={{
              marginRight: '4px',
              fill: 'currentColor',
            }}
          >
            <path d="M9 16.2l-3.5-3.5-1.4 1.4L9 19 19 9l-1.4-1.4L9 16.2z" />
          </svg>
          Copied
        </span>
      ) : (
        <CopyIcon size={16} />
      )}
    </button>
  );
};

export default CopyButton;
